import type { Country } from './orders';
import { EmailMethod, Org } from './orgs';
import { Roles } from './roles';

export enum Statuses {
  Active = 'active',
  Deactivated = 'deactivated',
  Suspended = 'suspended',
}

export type User = {
  _id: string,
  name: string,
  email: string,
  username: string,
  contact?: { phone?: string },
  dorothy_customer_id?: number,
  role: Roles,
  cognito_id: string
  org?: Org,
  favourite_products?: Array<{ category: string, code: string }>,
  email_method?: EmailMethod,
  country: Country,
  os_premium_requests: number,
  os_premium_requests_limit: number,
  marketing_consent?: boolean,
  created_at: Date | string,
  updated_at: Date | string,
  last_order_date?: Date | string,
  migrated?: boolean,
  status: Statuses,
  last_active_at?: Date | string,
  report_display_name?: string,
  report_display_email?: string,
  avatar_url?: string,
  tutorials?: string[],
  billing_details?: {
    finance_id: string,
  },
  tags?: string[],
};

export type BasicUser = Pick<User, '_id' | 'name' | 'email' | 'dorothy_customer_id' | 'email_method' | 'report_display_name' | 'report_display_email' | 'status'>;

export type SearchUsersQuery = {
  roles?: string,
  name?: string,
  email?: string,
  org_id?: string,
  no_org?: string,
  q?: string,
  status?: string,
};

export type UserPatchPayload = {
  name?: string,
  email?: string,
  phone?: string,
  role?: 'user' | 'org_admin',
  last_order_date?: Date | string,
  marketing_consent?: boolean,
  org_id?: string,
  org_name?: string,
  remove_org?: boolean,
  status?: string,
  report_display_name?: string,
  report_display_email?: string,
  tutorial?: string,
  finance_id?: string,
  email_method?: EmailMethod
};
