'use client';

import { useState } from 'react';
import { useFormik } from 'formik';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';
import * as yup from 'yup';
import { Statuses } from 'common/lib/types/user';
import Button from '@/src/components/Button/Button';
import FormikInput from '@/src/components/Input/FormikInput';
import UnderlinedLink from '@/src/components/Typography/UnderlinedLink';

type LoginForm = {
  email: string,
  password: string,
};

export default function Login() {
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();

  const validationSchema = () => yup.object({
    email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required'),
  });

  const onLogin = async (values: LoginForm) => {
    const { email, password } = values;

    try {
      setError('');
      const auth = await signIn('credentials', {
        email,
        password,
        redirect: false,
        callbackUrl: '/',
      });

      if (!auth?.ok || auth?.error) {
        if (auth?.error === Statuses.Deactivated) {
          setError('Account has been deactivated');
        } else {
          setError('Wrong username or password');
        }
      }

      if (!auth?.error) {
        router.push('/');
      }
    } catch (err) {
      console.error('login error', err);
    }
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const loginForm = useFormik<LoginForm>({
    initialValues,
    onSubmit: onLogin,
    validationSchema,
    validateOnChange: true,
  });

  return (
    <>
      {!!error && (
      <div className="bg-rose-100 py-2 w-full lg:pl-6">
        <span className="text-white text-xs sm:text-sm block text-center lg:text-left">{error}</span>
      </div>
      )}

      <div className="mt-10 pb-10 flex flex-col items-center w-full max-w-[482px]">

        <h1 className="font-museo-700 text-3xl text-blue-100 mb-4">WELCOME BACK!</h1>
        <h2 className="text-xl text-blue-100 mb-10">Login to Groundsure.io</h2>

        <form
          method="post"
          onSubmit={loginForm.handleSubmit}
          className="w-full px-10 flex flex-col items-center justify-center"
        >
          <FormikInput
            formik={loginForm}
            className="mb-6"
            type="email"
            name="email"
            id="email"
            data-testid="email"
            placeholder="Email"
            required
            startIcon={(
              <Image
                src="/assets/icons/user.svg"
                width={24}
                height={24}
                alt="user icon"
              />
            )}
          />

          <FormikInput
            formik={loginForm}
            className="mb-6"
            type={showPassword ? 'text' : 'password'}
            name="password"
            id="password"
            data-testid="password"
            required
            placeholder="Password"
            startIcon={(
              <Image
                src="/assets/icons/padlock.svg"
                width={24}
                height={24}
                alt="user icon"
              />
            )}
            endIcon={(
              <Image
                className="mr-4 ml-2 cursor-pointer"
                data-testid="show-password"
                src={showPassword ? '/assets/icons/eye.svg' : '/assets/icons/eyeOff.svg'}
                alt="user"
                width={24}
                height={24}
                onClick={() => setShowPassword((prevState) => !prevState)}
              />
            )}
          />

          <Button
            className="mb-4 w-full"
            data-testid="login-button"
            type="submit"
            disabled={!loginForm.dirty || !loginForm.isValid || loginForm.isSubmitting}
            text={loginForm.isSubmitting ? 'logging in...' : 'login'}
          />

          <div className="w-full">
            <UnderlinedLink href="/forgot-password">Forgot password?</UnderlinedLink>
          </div>
        </form>
      </div>
    </>
  );
}
